





























import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref,
} from "vue-property-decorator";

import api from "@/api";
import {
  DataDictionaryDto,
  DataDictionaryCreateOrUpdateDto,
} from "@/api/appService";
import { createNgTree, rebuildKeys, getKeys } from "@/utils/tree";
import { ElForm } from "element-ui/types/form";

@Component({
  name: "EditData",
})
export default class EditData extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({ required: true })
  dataId!: number;

  @Prop({ required: false })
  dataParent!: number;

  defaultData: DataDictionaryCreateOrUpdateDto = {
    key: "",
    displayName: "",
    parentId: 0,
    id: 0,
  };

  show = false;
  form: DataDictionaryCreateOrUpdateDto = { ...this.defaultData };

  get title() {
    if (this.dataId) {
      return `编辑 ${this.form.displayName}`;
    } else {
      return "新建";
    }
  }

  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog",
      });
      this.defaultData.parentId = this.dataParent;
      if (this.dataId) {
        await api.dataDictionary
          .get({ id: this.dataId })
          .then((res: DataDictionaryDto) => {
            this.form = res!;
            console.log("routesData", this.form);
          })
          .finally(() => {
            setTimeout(() => {
              loading.close();
            }, 200);
          });
      } else {
        this.form = { ...this.defaultData };
        //
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = { ...this.defaultData };
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);

    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.dataId) {
          await api.dataDictionary.update({
            body: { ...this.form },
          });
        } else {
          await api.dataDictionary.create({
            body: { ...this.form },
          });
        }
        this.show = false;
        this.$message.success("更新成功");
        this.$emit("onSave", this.form);
      }
    });
  }

  cancel() {
    this.show = false;
  }

  roleRule = {
    key: [
      {
        required: true,
        message: "请输入键值",
        trigger: "change",
      },
      {
        max: 100,
        message: "最多100个字符",
        trigger: "blur",
      },
    ],
    displayOrder: [
      {
        required: true,
        message: "请输入显示顺序",
        trigger: "change",
      },
      {
        type: "number",
        message: "显示顺序必须为数字",
        trigger: "change",
      },
    ],
    displayName: [
      {
        required: true,
        message: "请输入字典名称",
        trigger: "change",
      },
      {
        max: 100,
        message: "最多100个字符",
        trigger: "blur",
      },
    ],
  };
}
