

















































































import api from "@/api";
import { Component, Vue, Ref } from "vue-property-decorator";
import { CustomFormType } from "@/api/appService";
interface PartnerCustomFormItem {
  formId?: string;
  displayName: string;
  hostId: string;
}
@Component({
  name: "PartnerCustomForm",
})
export default class PartnerCustomForm extends Vue {
  items: PartnerCustomFormItem[] = [];
  items2: PartnerCustomFormItem[] = [];
  items3: PartnerCustomFormItem[] = [];

  settingList:any[]=[{

  }];

  async created() {
    await this.fetchDataDictionary();
    await this.fetchDataDictionary2();
    await this.fetchDataDictionary3();
  }
  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "OrgVolunteerApply",
        maxResultCount: 1000,
      })
      .then((res) => {
        this.items = res.items!.map((dictionary) => {
          return {
            displayName: dictionary.displayName,
            hostId: dictionary.id!.toString(),
            formId: "",
          } as PartnerCustomFormItem;
        });
        this.items.forEach((item) => {
          this.checkCustomForm(item);
        });
      });
  }
  checkCustomForm(item: PartnerCustomFormItem) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.Volunteer,
        hostId: item.hostId.toString(),
      })
      .then((res) => {
        item.formId = res;
      });
  }
  handleClick(item: PartnerCustomFormItem) {
    if (item.formId) {
      this.$router.push({
        name: "customFormDesign",
        query: {
          formId: item.formId,
          hostType: CustomFormType.Volunteer,
        },
      });
    } else {
      this.$router.push({
        name: "customFormDesign",
        query: {
          hostId: item.hostId,
          hostType: CustomFormType.Volunteer,
        },
      });
    }
  }



  async fetchDataDictionary2() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "OrganizationUnitType",
        maxResultCount: 1000,
      })
      .then((res) => {
        this.items2 = res.items!.map((dictionary) => {
          return {
            displayName: dictionary.displayName,
            hostId: dictionary.id!.toString(),
            formId: "",
          } as PartnerCustomFormItem;
        });
        this.items2.forEach((item) => {
          this.checkCustomForm2(item);
        });
      });
  }
  checkCustomForm2(item: PartnerCustomFormItem) {
    api.customFormService
      .getCustomFormId({
        hostType: CustomFormType.OrganizationUnit,
        hostId: item.hostId.toString(),
      })
      .then((res) => {
        item.formId = res;
      });
  }
  handleClick2(item: PartnerCustomFormItem) {
    if (item.formId) {
      this.$router.push({
        name: "customFormDesign",
        query: {
          formId: item.formId,
          hostType: CustomFormType.OrganizationUnit,
        },
      });
    } else {
      this.$router.push({
        name: "customFormDesign",
        query: {
          hostId: item.hostId,
          hostType: CustomFormType.OrganizationUnit,
        },
      });
    }
  }

  async fetchDataDictionary3() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ManageCustomForm",
        maxResultCount: 1000,
      })
      .then((res) => {
        this.items3 = res.items!.map((dictionary) => {
          return {
            displayName: dictionary.displayName,
            hostId: dictionary.id!.toString(),
            formId: "",
          } as PartnerCustomFormItem;
        });
        this.items3.forEach((item) => {
          this.checkCustomForm3(item);
        });
      });
  }
  checkCustomForm3(item: PartnerCustomFormItem) {
    let hostType=undefined;
    if(item.displayName=='服务管理总结表'){
      hostType=CustomFormType.ServiceManagement
    }
    if(item.displayName=='培训管理总结表'){
      hostType=CustomFormType.TrainManage
    }
    if(item.displayName=='活动管理总结表'){
      hostType=CustomFormType.ActivityManage
    }
    if(item.displayName=='星级评定总结表'){
      hostType=CustomFormType.StarRatingManagement
    }
    if(item.displayName=='服务对象反馈表'){
      hostType=CustomFormType.ServiceObjectFeedback
    }

    api.customFormService
      .getCustomFormId({
        hostType:hostType,
        hostId: item.hostId.toString(),
      })
      .then((res) => {
        item.formId = res;
      });
  }
  handleClick3(item: PartnerCustomFormItem) {
    let hostType=undefined;
    if(item.displayName=='服务管理总结表'){
      hostType=CustomFormType.ServiceManagement
    }
    if(item.displayName=='培训管理总结表'){
      hostType=CustomFormType.TrainManage
    }
    if(item.displayName=='活动管理总结表'){
      hostType=CustomFormType.ActivityManage
    }
    if(item.displayName=='星级评定总结表'){
      hostType=CustomFormType.StarRatingManagement
    }
    if(item.displayName=='服务对象反馈表'){
      hostType=CustomFormType.ServiceObjectFeedback
    }

    if (item.formId) {
      this.$router.push({
        name: "customFormDesign",
        query: {
          formId: item.formId,
          hostType: hostType,
        },
      });
    } else {
      this.$router.push({
        name: "customFormDesign",
        query: {
          hostId: item.hostId,
          hostType: hostType,
        },
      });
    }
  }
}
